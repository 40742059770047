/**
 * Main JS
 */
(function($) {


  function set_body_padding () {
    let hh = $('header.header').outerHeight();
    $('body').css('padding-top', hh);
  }//end set_body_padding


  function set_headings_height() {
    let item = $('div.overflow-carousel div.owl-item div.heading-wrap');
    if (typeof item !== 'undefined') {
      let min_h = 0;
      item.attr('style', '');
      item.each(function () {
        let $this = $(this),
          this_h = parseInt($this.height());

        if (min_h < this_h) {
          min_h = this_h;
        }
      });

      item.height(min_h);
    }
  }


  function set_dots_aria_labels ($carousel) {
    $carousel.find('.owl-dot').each(function(index) {
      $(this).attr('aria-label', index + 1);
    });
  }//end set_dots_aria_labels


  function set_nav_role_attr($carousel) {
    $carousel.find('button[class^="owl-"]').each(function(index) {
      $(this).attr('role', 'button');
    });
  }


  $(function() {


    //Set the body padding top on window resize to accommodate fixed header
    $(window).resize(function () {
      set_body_padding();
    });


    //Mobile nav menu click
    $(document).on('click', 'div.mobile-menu-toggle button', function () {
      let $this = $(this),
          navbar = $('nav.navbar');

      navbar.find('div.navbar-collapse').toggleClass('collapse');

      //Open nav
      if ($this.hasClass('closed')) {
        $this.attr('aria-expanded', 'true').removeClass('closed');
      } else {
        $this.attr('aria-expanded', 'false').addClass('closed');
      }

    });


    if ($('.testimonials-carousel').length) {

      let testimonial_carousel = $('.testimonials-carousel');
      testimonial_carousel.each(function(){

        let $this = $(this),
            items = $this.data('cols');

        $this.owlCarousel({
          items: items,
          dots: true,
          nav: false,
          responsiveClass: true,
          responsive: {
            0: {
              items: 1,
            },
            767: {
              items: 1,
            },
            991: {
              items: 1,
            },
            992: {
              items: items,
            }
          },
          onInitialized: function(){
            set_dots_aria_labels($this);
            set_nav_role_attr($this);
          }
        });//end img carousel

      });

    }


    if ($('.overflow-carousel').length) {

      $('.overflow-carousel').owlCarousel({
        items: 1,
        dotsContainer: '#owl-dots-container',
        navContainer: '#owl-nav-container',
        margin: 30,
        navText: ['<img src="/wp-content/themes/MDSustain/assets/img/svg/arrow-left-teal.svg" alt="Teal left arrow icon">', '<img src="/wp-content/themes/MDSustain/assets/img/svg/arrow-right-teal.svg" alt="Teal right arrow icon">'],
        onInitialized: function(){
          $('div#owl-nav-container button.owl-prev').addClass('inactive');
          set_headings_height();
          set_dots_aria_labels($('#owl-dots-container'));
          set_nav_role_attr($('#owl-nav-container'));
        },
        onChanged: function(e){
          if (e.item.index === 0) {
            $('div#owl-nav-container button.owl-prev').addClass('inactive');
          } else if (e.item.index === (e.item.count - 1)) {
            $('div#owl-nav-container button.owl-next').addClass('inactive');
          } else {
            $('div#owl-nav-container button').removeClass('inactive');
          }
        },
        onResized:function(){
          set_headings_height();
        },
        responsiveClass: true,
        responsive: {
          0: {
            margin: 0,
          },
          767: {
            margin: 60,
          },
          991: {
            margin: 60,
          },
          992: {
            margin: 30,
          }
        }
      });//end img carousel

    }


    if ($('.md-tool-tip').length) {

      $('a.md-tool-tip').click(function(e){
        e.preventDefault();
        return false;
      });

      let tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      tooltipTriggerList.map(function (tooltipTriggerEl) {
        return new bootstrap.Tooltip(tooltipTriggerEl)
      });

    }


  });

})(jQuery); // Fully reference jQuery after this point.
